<template>
  <v-container
    fluid
    fill-height
    :class="$style.auth"
    class="d-flex justify-start align-center flex-column pa-0"
  >
    <v-overlay :value="processing">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <div>
      <v-row dense>
        <v-col cols="12" class="text-center">
          <router-link :to="{ name: 'mypage-index' }">
            <img :src="require('@/assets/logo.svg')" width="180" height="88" />
          </router-link>
        </v-col>
      </v-row>
      <v-row dense class="mt-1 mb-0">
        <v-col cols="12" class="text-center">
          <h2 :class="$style.title">{{ title }}</h2>
        </v-col>
      </v-row>
      <v-row dense class="mb-4 mt-0">
        <v-col cols="12" class="text-center">
          <h3 :class="$style.title">{{ titleEn }}</h3>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" class="d-flex justify-center">
          <v-card
            color="#fff"
            class="py-1 pa-5 mb-3 rounded-xl"
            rounded
            :class="$style.form"
          >
            <v-row dense>
              <v-col cols="12">
                <v-list flat class="py-0">
                  <v-list-item-group>
                    <template v-for="(field, index) in fields">
                      <v-list-item
                        :key="index"
                        :to="`/account/${field.name}`"
                        class="pa-0"
                      >
                        <v-list-item-content>
                          <v-list-item-title
                            v-text="field.label"
                          ></v-list-item-title>
                          <v-list-item-title
                            v-text="field.labelEn"
                          ></v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-content>
                          <v-list-item-subtitle v-text="input[field.name]">
                          </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-icon>
                          <v-icon>mdi-chevron-right</v-icon>
                        </v-list-item-icon>
                      </v-list-item>
                      <v-divider
                        v-if="index !== fields.length - 1"
                        :key="`divide-${index}`"
                        :class="$style.separator"
                      ></v-divider>
                    </template>
                  </v-list-item-group>
                </v-list>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center">
        <v-col class="d-flex justify-center">
          <v-btn
            class="text-center"
            text
            color="#5993ac"
            @click="onClickDeleteAccountBtn"
            :class="$style.account_remove"
          >
            アカウント削除 (Delete Account)<v-icon
              :class="$style.account_remove_icon"
              >mdi-chevron-right</v-icon
            >
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center">
        <v-col cols="6">
          <v-btn
            class="text-center"
            exact
            text
            block
            color="#5993ac"
            :class="$style.back"
            :to="{ name: 'mypage-index' }"
          >
            <v-icon :class="$style.back_icon">mdi-chevron-left</v-icon>戻る
            (Back)
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import utility from "@/utility";

export default {
  data() {
    return {
      resourceName: "accounts",
      formKey: "account-index",
      title: "アカウント情報",
      titleEn: "Account Details",
      input: {},
      fields: [
        {
          label: "ユーザ名",
          labelEn: "User Name",
          name: "name"
        },
        {
          label: "メールアドレス",
          labelEn: "Email Address",
          name: "email"
        },
        {
          label: "電話番号",
          labelEn: "Phone Number",
          name: "phone"
        },
        {
          label: "パスワード",
          labelEn: "Password",
          name: "password"
        }
      ]
    };
  },
  computed: {
    isError() {
      return this.$store.getters[`${this.resourceName}/entity/isError`];
    },
    processing() {
      return this.$store.getters[`${this.resourceName}/entity/processing`];
    },
    entity() {
      return this.$store.getters[`${this.resourceName}/entity/entity`];
    },
    errorMessages() {
      return this.$store.getters[`${this.resourceName}/entity/errorMessages`];
    },
    userName() {
      const { accountInfo } = this.entity;
      if (accountInfo.firstName && accountInfo.lastName) {
        return `${accountInfo.firstName} ${accountInfo.lastName}` || "";
      }
      return "";
    }
  },
  methods: {
    popstate() {
      window.history.pushState(null, null, null);
      return;
    },
    onClickLogoutBtn() {
      this.$store.dispatch("auth/logout");
    },
    onClickDeleteAccountBtn() {
      utility
        .$confirm(
          "アカウントを削除すると、JaM id をご利用いただいている全てのサービスの機能が使用できなくなります。本当に削除してよろしいですか？ If you delete your account, you will not be able to use the functions of all services that use JaM id. Are you sure you want to delete it?",
          "確認 (Confirmation)",
          {
            confirmButtonText: "OK",
            cancelButtonText: "Cancel",
            type: "warning"
          }
        )
        .then(() => {
          this.$store.dispatch(`${this.resourceName}/entity/withdrawal`);
        })
        .catch(() => {});
    }
  },
  created() {
    this.$store.commit(`auth/initialize`);
    this.$store.commit(`${this.resourceName}/entity/initialize`);
  },
  mounted() {
    window.addEventListener("popstate", this.popstate);
    this.popstate();

    this.$store
      .dispatch(`${this.resourceName}/entity/getAccountInfo`)
      .then(() => {
        this.input = {
          name: this.userName,
          email: this.entity.accountInfo.mailAddress,
          phone: this.entity.accountInfo.phoneNumberInfo
            ? this.entity.accountInfo.phoneNumberInfo.phoneNumber
            : null
        };
      });
  },
  beforeDestroy() {
    window.removeEventListener("popstate", this.popstate);
    this.$store.commit("form/changeDirty", [
      {
        key: this.formKey,
        isDirty: !!this.mailAddress
      }
    ]);
  }
};
</script>

<style module lang="scss">
@import "@/styles/common.scss";
.title {
  font-size: 16px;
  color: #6f808c;
  font-weight: 600;
}

.form {
  background-color: #ffffff;
  width: 100%;
  min-width: 320px;
  max-width: 320px;
  padding: 15px 30px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.05) !important;
}

.error {
  color: #ff1100;
  text-align: center;
  font-size: 12px;
}

.separator {
  border-color: rgba(0, 0, 0, 0.05) !important;
}

.back {
  font-size: 13px !important;
  font-family: $body-font-family;
}

.back_icon {
  margin-top: 2px;
}

.account_remove {
  font-size: 13px !important;
  font-family: $body-font-family;
}

.account_remove_icon {
  margin-top: 2px;
}
</style>

<style scope lang="scss">
.theme--light.v-btn.v-btn--disabled {
  color: #fff !important;
}

.v-list {
  .v-list-item {
    .v-list-item__title {
      color: #718490;
      font-size: 1.3rem;
    }

    .v-list-item__subtitle {
      font-size: 1.2rem;
      color: #718490;
    }

    .v-list-item__icon {
      .v-icon {
        color: #718490;
      }
    }
  }
}
</style>
